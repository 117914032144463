import React, { useCallback, useMemo, useState } from "react"
import PropTypes from "prop-types"
import * as styles from "./CaseStudyDownload.module.scss"
import CustomCard from "components/common/ui/CustomCard"
import { graphql, useStaticQuery } from "gatsby"
import UniversalImage from "components/common/ui/UniversalImage"
import { translate } from "src/utils/language"
import useCurrentLanguage from "src/hooks/useCurrentLanguage"
import CaseStudyLabel from "./CaseStudyLabel"
import CaseStudyDownloadModal from "./CaseStudyDownloadModal"
import { Button } from "react-bootstrap"

const CaseStudyDownload = ({ formId, portalId, image, title }) => {
  const data = useStaticQuery(graphql`
    {
      localFile: file(relativePath: { eq: "case-download-bg.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 500, layout: CONSTRAINED)
        }
      }
      caseStudySettingsPage: wpSettingsPage(slug: { eq: "case-studies" }) {
        ...CaseStudyDownloadFragment
        translations {
          ...CaseStudyDownloadFragment
        }
      }
    }
  `)

  const locale = useCurrentLanguage()
  const {
    caseStudySettings: {
      details: {
        download: { title: label, description, emailLabel },
      },
    },
  } = translate(data.caseStudySettingsPage, locale)

  const toggleDownloadModal = useCallback(() => {
    setShowDownloadModal(value => !value)
  }, [])

  const [showDownloadModal, setShowDownloadModal] = useState(false)

  const bgImage = useMemo(() => ({ localFile: data.localFile }), [
    data.localFile,
  ])

  return (
    <CustomCard small>
      <UniversalImage image={bgImage} className={styles.bgImage} />
      <div className={styles.content}>
        <h5 className={styles.heading}>{label}</h5>
        <p>
          <CaseStudyLabel template={description} caseStudyTitle={title} />
        </p>
        <Button
          onClick={toggleDownloadModal}
          className={styles.downloader}
          variant="outline-light"
        >
          {label}
        </Button>
      </div>

      <CaseStudyDownloadModal
        show={showDownloadModal}
        handleClose={toggleDownloadModal}
        formId={formId}
        portalId={portalId}
        image={image}
        label={label}
        description={description}
        caseName={title}
        emailLabel={emailLabel}
      />
    </CustomCard>
  )
}

CaseStudyDownload.propTypes = {
  formId: PropTypes.string,
  portalId: PropTypes.string,
  image: PropTypes.object,
  title: PropTypes.string,
}

export default CaseStudyDownload
