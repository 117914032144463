import React, { useMemo } from "react"
import PropTypes from "prop-types"

const regexp = /(.*)({case-study})(.*)/

const CaseStudyLabel = ({ template, caseStudyTitle, suffix }) => {
  const matchedTemplateParts = useMemo(
    () => template.match(regexp).filter((_, index) => index !== 0),
    [template]
  )

  return (
    <>
      {matchedTemplateParts.map((part, partIndex) =>
        part === "{case-study}" ? (
          <span key={partIndex}>{caseStudyTitle}</span>
        ) : (
          <span key={partIndex}>{part}</span>
        )
      )}
      {suffix && <span> {suffix}</span>}
    </>
  )
}

CaseStudyLabel.propTypes = {
  template: PropTypes.string,
  caseStudyTitle: PropTypes.string,
  suffix: PropTypes.string,
}

export default CaseStudyLabel
