import React from "react"
import PropTypes from "prop-types"
import * as styles from "./CaseStudyDetails.module.scss"
import CustomCard from "components/common/ui/CustomCard"
import MaterialIcon from "components/common/ui/MaterialIcon"
import classNames from "classnames"
import { graphql, useStaticQuery } from "gatsby"
import { translate } from "src/utils/language"
import useCurrentLanguage from "src/hooks/useCurrentLanguage"

const CaseStudyDetails = ({
  industry,
  projectTeam,
  projectTime,
  projectTimeDescription,
  supportSla,
  region,
  typeOfProject,
}) => {
  const options = useStaticQuery(graphql`
    query cssettings {
      caseStudySettingsPage: wpSettingsPage(slug: { eq: "case-studies" }) {
        ...CaseStudyDetailsFragment
        translations {
          ...CaseStudyDetailsFragment
        }
      }
    }
  `)

  const locale = useCurrentLanguage()
  const {
    caseStudySettings: {
      details: { details: detailsLabels },
    },
  } = translate(options.caseStudySettingsPage, locale)

  return (
    <CustomCard small className={styles.wrapper}>
      <h5 className={styles.title}>{detailsLabels.details}</h5>
      <div className={styles.row}>
        <MaterialIcon className={styles.icon}>󰅆</MaterialIcon>
        <div>
          <h6 className={styles.itemTitle}>{detailsLabels.industry}</h6>
          <p className={styles.itemLabel}>{industry}</p>
        </div>
      </div>
      <div className={styles.row}>
        <MaterialIcon className={styles.icon}>󰵰</MaterialIcon>
        <div>
          <h6 className={styles.itemTitle}>{detailsLabels.supportSla}</h6>
          <p className={styles.itemLabel}>{supportSla}</p>
        </div>
      </div>
      <div className={styles.row}>
        <MaterialIcon className={styles.icon}>󰃰</MaterialIcon>
        <div>
          <h6 className={styles.itemTitle}>{detailsLabels.projectTime}</h6>
          <p className={styles.itemLabel}>
            {projectTimeDescription || projectTime}
          </p>
        </div>
      </div>
      <div className={styles.row}>
        <MaterialIcon className={styles.icon}>󰡉</MaterialIcon>
        <div>
          <h6 className={styles.itemTitle}>{detailsLabels.projectTeam}</h6>
          <p className={classNames(styles.itemLabel, styles.itemPreWrap)}>
            {projectTeam}
          </p>
        </div>
      </div>
      <div className={styles.row}>
        <MaterialIcon className={styles.icon}>󰍎</MaterialIcon>
        <div>
          <h6 className={styles.itemTitle}>{detailsLabels.region}</h6>
          <p className={styles.itemLabel}>{region}</p>
        </div>
      </div>
      <div className={styles.row}>
        <MaterialIcon className={styles.icon}>󰓹</MaterialIcon>
        <div>
          <h6 className={styles.itemTitle}>{detailsLabels.typeOfProject}</h6>
          <p className={styles.itemLabel}>{typeOfProject}</p>
        </div>
      </div>
    </CustomCard>
  )
}

CaseStudyDetails.propTypes = {
  industry: PropTypes.string,
  projectTeam: PropTypes.string,
  projectTime: PropTypes.string,
  supportSla: PropTypes.string,
  region: PropTypes.string,
  typeOfProject: PropTypes.string,
}

export default CaseStudyDetails
