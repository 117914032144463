import React from "react"
import PropTypes from "prop-types"
import { Col, Modal, Row } from "react-bootstrap"
import * as styles from "./CaseStudyDownloadModal.module.scss"
import CustomHubspotForm from "../CustomHubspotForm"
import CaseStudyLabel from "./CaseStudyLabel"
import UniversalImage from "../ui/UniversalImage"

const CaseStudyDownloadModal = ({
  formId,
  portalId,
  image,
  label,
  description,
  caseName,
  emailLabel,
  show = false,
  handleClose,
}) => (
  <Modal show={show} onHide={handleClose} size="lg">
    <Modal.Header closeButton>
      <Modal.Title>{label}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <p>
        <CaseStudyLabel
          template={description}
          caseStudyTitle={caseName}
          suffix={emailLabel}
        />
      </p>
      <Row className={styles.row}>
        <Col lg={6} className={styles.col}>
          <CustomHubspotForm formId={formId} portalId={portalId} />
        </Col>
        <Col lg={5} sm={8} className={styles.col}>
          <UniversalImage image={image} className={styles.imageWrapper} />
        </Col>
      </Row>
    </Modal.Body>
  </Modal>
)

CaseStudyDownloadModal.propTypes = {
  formId: PropTypes.string,
  portalId: PropTypes.string,
  image: PropTypes.object,
  label: PropTypes.string,
  description: PropTypes.string,
  caseName: PropTypes.string,
  emailLabel: PropTypes.string,
  show: PropTypes.bool,
}

export default CaseStudyDownloadModal
