import React from "react"
import PropTypes from "prop-types"
import * as styles from "./CaseStudyTechnologies.module.scss"
import CustomCard from "components/common/ui/CustomCard"
import { graphql, useStaticQuery } from "gatsby"
import UniversalImage from "components/common/ui/UniversalImage"
import UniversalLink from "components/common/ui/UniversalLink"
import { Button } from "react-bootstrap"
import useCurrentLanguage from "src/hooks/useCurrentLanguage"
import { translate } from "src/utils/language"

const CaseStudyTechnologies = ({ appliedTechnologies }) => {
  const options = useStaticQuery(graphql`
    query {
      caseStudySettingsPage: wpSettingsPage(slug: { eq: "case-studies" }) {
        ...CaseStudyTechnologiesFragment
        translations {
          ...CaseStudyTechnologiesFragment
        }
      }
    }
  `)

  const locale = useCurrentLanguage()
  const {
    caseStudySettings: {
      details: {
        technologies: { buttonText, buttonUrl, label },
      },
    },
  } = translate(options.caseStudySettingsPage, locale)

  return (
    <CustomCard small>
      <h5 className={styles.title}>{label}</h5>
      {appliedTechnologies && (
        <div className={styles.icons}>
          {appliedTechnologies.map(({ logo }, index) => (
            <UniversalImage key={index} image={logo} />
          ))}
        </div>
      )}
      {buttonUrl?.uri && (
        <div className={styles.buttonWrapper}>
          <Button
            as={UniversalLink}
            to={buttonUrl.uri}
            variant="outline-primary"
          >
            {buttonText}
          </Button>
        </div>
      )}
    </CustomCard>
  )
}

CaseStudyTechnologies.propTypes = { appliedTechnologies: PropTypes.array }

export default CaseStudyTechnologies
