import React from "react"
import Layout from "components/layouts/Layout"
import SEO from "components/common/Seo"
import PageTitle from "components/common/ui/PageTitle"
import { Col, Container, Row } from "react-bootstrap"
import CustomCard from "components/common/ui/CustomCard"
import FoldingFan from "components/common/ui/FoldingFan"
import WysiwygContent from "components/common/ui/WysiwygContent"
import Badges from "components/common/ui/Badges"
import TestimonialSlide from "components/blocks/TestimonialsBlock/components/TestimonialSlide"
import CaseStudyDetails from "components/common/CaseStudy/CaseStudyDetails"
import CaseStudyTechnologies from "components/common/CaseStudy/CaseStudyTechnologies"
import CaseStudyDownload from "components/common/CaseStudy/CaseStudyDownload"
import { graphql } from "gatsby"
import * as styles from "./CaseStudy.module.scss"
import useCurrentLanguage from "src/hooks/useCurrentLanguage"
import { translate } from "src/utils/language"
import BlocksLayout from "components/layouts/BlocksLayout"

const CaseStudy = ({ data, pageContext }) => {
  const {
    caseStudy: {
      attrs: {
        title,
        content,
        tags,
        testimonial,
        details,
        appliedTechnologies,
        downloadFormId,
        downloadFormPortalId,
      },
      featuredImage,
      pageBuilder: { blocks },
    },
  } = data

  const testimonialData = testimonial?.testimonialAttributes
    ? {
        description: testimonial.testimonialAttributes.recommendation,
        authorName: testimonial.testimonialAttributes.contact.name,
        authorPosition: testimonial.testimonialAttributes.contact.role,
        authorImage: testimonial.testimonialAttributes.contact.image,
        company: testimonial.testimonialAttributes.contact.company,
      }
    : null

  const locale = useCurrentLanguage()
  const {
    caseStudySettings: { buttonPage },
  } = translate(data.caseStudySettingsPage, locale)

  return (
    <Layout page={pageContext?.page}>
      <SEO {...pageContext.seo} />
      <main className={styles.upperContent}>
        <div className={styles.titleWrapper}>
          <PageTitle title={title} parentNode={buttonPage} />
          <Container className={styles.badgesContainer}>
            <Badges small items={tags} />
          </Container>
        </div>
        <Container>
          <Row>
            <Col lg={3} md={4}>
              <aside className={styles.sidebar}>
                <div className={styles.sidebarItem}>
                  <CaseStudyDetails {...details} />
                </div>
                <div className={styles.sidebarItem}>
                  <CaseStudyTechnologies
                    appliedTechnologies={appliedTechnologies}
                  />
                </div>
                <div className={styles.sidebarItem}>
                  <CaseStudyDownload
                    formId={downloadFormId}
                    portalId={downloadFormPortalId}
                    image={featuredImage?.node}
                    title={title}
                  />
                </div>
              </aside>
            </Col>
            <Col lg={9} md={8}>
              <div className={styles.cardWrapper}>
                <CustomCard>
                  <FoldingFan className={styles.foldingFan} />
                  {testimonial && (
                    <div className={styles.testimonialWrapper}>
                      <TestimonialSlide {...testimonialData} />
                    </div>
                  )}
                  <WysiwygContent
                    dangerouslySetInnerHTML={{ __html: content }}
                    className={styles.content}
                  />
                </CustomCard>
              </div>
            </Col>
          </Row>
        </Container>
      </main>
      {blocks && <BlocksLayout blocks={blocks} />}
    </Layout>
  )
}

CaseStudy.propTypes = {}

export const query = graphql`
  query caseStudy($id: String!) {
    caseStudy: wpCaseStudy(id: { eq: $id }) {
      ...CaseStudyTemplateFragment
    }
    caseStudySettingsPage: wpSettingsPage(slug: { eq: "case-studies" }) {
      ...CaseStudiesBlockSettingsFragment
      translations {
        ...CaseStudiesBlockSettingsFragment
      }
    }
  }
`

export default CaseStudy
